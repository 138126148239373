import close from '/images/close.svg'
import burger from '/images/burger.svg'

// window.addEventListener('click', (event) => {
//     event.preventDefault();
// })

function getCurrentURL() {
    return window.location.pathname;
}

function getURL() {
    return window.location.href;
}

let currentURL = getCurrentURL();
console.log(currentURL);

let url = getURL();

// console.log(url);

const links = document.querySelectorAll('.nav-item');
// console.log[links[0]];

// const fakeLink = document.querySelector('fake-mobile-nav-item');

links.forEach(link => {
  
    if (link.href.includes(`${currentURL}`)) {
        link.classList.add('active');
        link.classList.remove('nav-item');
        // fakeLink.classList.add('active');
    } 
  
    // else  {
    //     link[6].classList.add('active');
    //     link.classList.remove('nav-item');
    //     // fakeLink.classList.add('active');
    // } 
});


const mobileLinks = document.querySelectorAll('.mobile-nav-item');

mobileLinks.forEach(link => {
    if (link.href.includes(`${currentURL}`)) {
        link.classList.add('mobile-active');
    }
});

const menuBtn = document.querySelector('.mobile-menu-btn');
const mobileMenu = document.querySelector('.mobile-menu');
const wrapper = document.querySelector('.wrapper');
const body = document.querySelector('body');

const burgerMenu = document.querySelector('#burger-menu');

const toggleMenu = () => {
    mobileMenu.classList.toggle('moblie-menu-active-menu');
    wrapper.classList.toggle('moblie-menu-active-wrapper');
    body.style.overflowX = "hidden";
    body.style.overflowY = "auto";

    // if statement hier
    burgerMenu.setAttribute('src', burger);
    if (mobileMenu.classList.contains('moblie-menu-active-menu')) {
        burgerMenu.setAttribute('src', close);
        body.style.overflowY = "hidden";
    }
}

menuBtn.addEventListener('click', toggleMenu);

const accordionBtn = document.querySelector('.accordion-label');
const content = document.querySelector('.accordion-content');
const arrow = document.querySelector('.accordion-arrow');

if(currentURL.includes('spettacoli-speciali') || currentURL.includes('sonderveranstaltungen') ) {
    content.classList.toggle('accordion-expanded');
    arrow.classList.toggle('rotate');
}

const toggleAccordion = () => {
    content.classList.toggle('accordion-expanded');
    arrow.classList.toggle('rotate');
}

accordionBtn.addEventListener('click', toggleAccordion);

// up arrow 


let options = {
    root: null,
    rootMargin: '1000px',
    threshold: 1
}

const footer = document.querySelector('.footer');
  
const createScrollArrow = () => {

    // if(entry.isIntersecting) {
    //     // show
    // } else {
    //     // sshow not
    // }

    // console.log('fired');
    const button = document.createElement('button');
    button.className = "scrollButton";
    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="30.698" height="31.504" viewBox="0 0 30.698 31.504">
    <path id="Icon_awesome-arrow-up" data-name="Icon awesome-arrow-up" d="M2.454,20.355.893,18.795a1.681,1.681,0,0,1,0-2.384L14.555,2.742a1.681,1.681,0,0,1,2.384,0L30.6,16.4a1.681,1.681,0,0,1,0,2.384l-1.561,1.561a1.689,1.689,0,0,1-2.412-.028l-8.065-8.466V32.063a1.683,1.683,0,0,1-1.687,1.688h-2.25a1.683,1.683,0,0,1-1.687-1.687V11.855L4.866,20.327a1.677,1.677,0,0,1-2.412.028Z" transform="translate(-0.397 -2.246)"/>
  </svg>
  `
    // button.setAttribute('src', "arrow");
    button.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    })
    footer.appendChild(button);
}

createScrollArrow();


// sticky header

// let observer = new IntersectionObserver(createScrollArrow, options);
// observer.observe(body);

// let observer = new IntersectionObserver(entries => {
//     console.log(entries);
// }, options);
  

// const left = document.querySelector('.left');
// const right = document.querySelector('.right');
// const wrap = document.querySelector('.wrapper');
// console.log(wrap);

// const bodyy = document.body;
// const html = document.documentElement; 
// const height = Math.max(bodyy.getBoundingClientRect().height, html.getBoundingClientRect().height);
// console.log(height)
// function createBlocks(side) {
//     let viewportHeight = wrap.scrollHeight/5;
//     console.log(viewportHeight)

//     // while loop

//     // let i = 0;

//     // do {
//     //     let block = document.createElement('div');
//     //     block.style.width = "20px";
//     //     block.style.height = "20px";
//     //     block.style.backgroundColor = "white";
//     //     block.style.marginBottom  = "1rem";
//     //     block.style.borderRadius = "2px";        
//     //     side.appendChild(block);
//     // } 
//     // while(i < viewportHeight);

//     for(let i = 10; i < 250; i++) {
//         let block = document.createElement('div');
//         block.style.width = "30px";
//         block.style.height = "20px";
//         block.style.backgroundColor = "white";
//         // block.style.marginBottom  = "1rem";
//         block.style.borderRadius = "2px";        
//         side.appendChild(block);
//     }
// }

// createBlocks(left);
// createBlocks(right);